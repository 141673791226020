import React, { ReactElement, useContext } from "react";
import { ThemeContext, ThemeProvider } from "styled-components";

import Profile from "components/Profile";
import { addEditAttributes } from "utils/episerver";

import {
    Container,
    Quote,
    Link,
    LinkText,
    IconArrow,
} from "./BlockQuote.styled";
import BlockQuoteProps from "./BlockQuoteProps";

const BlockQuote = ({
    quoteText,
    link,
    className,
    size = "small",
    theme,
    author,
}: BlockQuoteProps): ReactElement => {
    const themeContext = useContext(ThemeContext);
    theme = theme || (themeContext && themeContext.theme) || "lightgray";
    const isEmbedded = className?.includes("embedded");

    return (
        <ThemeProvider theme={{ size: size, theme: theme }}>
            <Container className={className}>
                <Quote {...addEditAttributes("QuoteText")}>{quoteText}</Quote>
                {author && (
                    <Profile
                        imageUrl={
                            size === "small" ? author.imageUrl : undefined
                        }
                        fullName={author.fullName}
                        role={author.role || author.description}
                        size={size}
                        hideInitials={true}
                    />
                )}
                {!isEmbedded && // Do not show links on embedded blockQuotes
                    link?.url && (
                        <Link
                            {...link}
                            hoverSpan={true}
                            gtmClickType="quote"
                            {...addEditAttributes("Link")}
                        >
                            {link.linkText && (
                                <LinkText>
                                    <IconArrow
                                        icon={"longArrowRight28"}
                                        aria-hidden="true"
                                    />
                                    {link.linkText}
                                </LinkText>
                            )}
                        </Link>
                    )}
            </Container>
        </ThemeProvider>
    );
};

export default React.memo(BlockQuote);
